import { useStaticQuery, graphql } from "gatsby"

export const useFaqsData = () => {
  const data = useStaticQuery(
    graphql`
      query AllFaqsQuery {
        allPrismicFaq {
          edges {
            node {
              data {
                category
                description {
                  text
                  html
                }
                title {
                  text
                }
              }
            }
          }
        }
      }
    `
  )
  return data.allPrismicFaq.edges
}
