import * as React from "react"
import styled, { css } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Goal from "./goal"
import Tabs from "./tabs"
import Pager from "../../shared/pager"
import { vw } from "../../../utilities/pxtovw"
import { goals } from "../../../data/goals"
import { fadeInLeft } from "../../../utilities/aimations"

const Items = styled.ul`
  display: none;
  flex-wrap: nowrap;
  padding-bottom: ${vw(84)};

  li {
    padding-left: 3vw;
    display: none;
    flex: 1;

    &.is-active {
      display: block;
      animation: ${fadeInLeft} 0.5s ease both;
    }
  }

  ${props =>
    props.active &&
    css`
      display: flex;
    `}

  @media (max-width: 766px) {
    flex-wrap: wrap;
    padding-top: 3.5rem;

    li {
      flex: auto;
      padding-left: 0;
    }
  }
`

const Ele = () => {
  const [activeGroup, setActiveGroup] = React.useState(0)
  const [page, setPage] = React.useState(1)
  const [pageCount, setPageCount] = React.useState(0)
  const [pageSize, setPageSize] = React.useState(4)

  const showGroup = e => {
    setActiveGroup(e)

    //set page count
    let groupGoalsCount = goals[e].items.length
    setPageCount(groupGoalsCount / 4)
    setPage(1)
  }

  const goBack = () => {
    if (page > 1) {
      setPage(page - 1)
    } else {
      setPage(pageCount)
    }
  }

  const goNext = () => {
    if (page < pageCount) {
      setPage(page + 1)
    } else {
      setPage(1)
    }
  }

  React.useEffect(() => {
    showGroup(0)
    if (window.innerWidth < 766) {
      setPageSize(goals[0].items.length)
    }
  }, [])

  // goal images
  const goalImages = useStaticQuery(graphql`
    query goalImagesQuery {
      allFile(filter: { relativeDirectory: { eq: "goals" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Tabs onClick={e => showGroup(e)} />
      {goals &&
        goals.map((group, index) => {
          return (
            <Items key={index} active={index === activeGroup ? true : false}>
              {group.items &&
                group.items.map((item, key) => {
                  //get goal image
                  let image = goalImages.allFile.edges.find(
                    ele => ele.node.name === item.image
                  )
                  return (
                    <li
                      className={
                        key >= pageSize * (page - 1) &&
                        key <= page * pageSize - 1
                          ? "is-active"
                          : ""
                      }
                      key={key}
                      style={{
                        animationDelay: Math.abs(page - key) / 20 + "s",
                      }}
                    >
                      <Goal
                        label={item.label}
                        cover={item.image}
                        caption={item.caption}
                        content={item.content}
                        image={image.node}
                      />
                    </li>
                  )
                })}
            </Items>
          )
        })}
      <Pager
        onBack={() => goBack()}
        onNext={() => goNext()}
        count={pageCount}
        active={page}
      />
    </>
  )
}
export default Ele
