import * as React from "react"
import styled from "styled-components"
import Title from "../../shared/title"
import Overline from "../../shared/overline"
import { vw } from "../../../utilities/pxtovw"
import GetStartedBtn from "../../shared/get-started-btn"
import Item from "./item"

const Box = styled.div`
  width: ${vw(630)};
  padding-left: 5rem;

  ul {
    padding-top: 2rem;
    display: flex;
    flex-wrap: wrap;

    li {
      width: 50%;
    }
  }

  @media (max-width: 766px) {
    width: auto;
    padding: 2rem;

    li {
      width: 100%;
    }
  }
`

const Ele = () => {
  return (
    <Box>
      <Overline>Saving</Overline>
      <Title>
        <blockquote>
          <p className="small">
            "Very user friendly and beautifully designed. I can now{" "}
            <span>track</span> my investments anywhere, anytime"
          </p>
          <cite>- Elizabeth</cite>
        </blockquote>
      </Title>
      <ul>
        <Item
          label="Advice"
          caption="Access the professional guidance from our advisors."
          icon="award"
        />
        <Item
          label="Deposit"
          caption="Deposit regularly using MTN/Airtel mobile money or Bank transfer."
          icon="plus-fill"
        />
        <Item
          label="Monitor"
          caption="Track performance and progress via our website, app, or MTN USSD."
          icon="phone"
        />
        <Item
          label="Withdraw"
          caption="Withdraw any amount needed and the rest stays invested."
          icon="download"
        />
      </ul>
      <GetStartedBtn
        title="Start Saving"
        className="padd-top-med"
        section="saving"
        id="start_saving_button"
      />
    </Box>
  )
}
export default Ele
