import * as React from "react"
import styled, { css } from "styled-components"
import Title from "../../shared/title"
import GetStartedBtn from "../../shared/get-started-btn"
import Overline from "../../shared/overline"
import { vw } from "../../../utilities/pxtovw"
import { useInView } from "react-intersection-observer"

const slides = [
  {
    title: "Select your goal",
    caption: "And set your financial targets",
  },
  {
    title: "Assess your risk appetite",
    caption: "By answering simple questions",
  },
  {
    title: "Verify your profile. Start investing",
    caption: "From UGX 10,000 using Bank or MTN/Airtel",
  },
]

const Box = styled.div`
  width: ${vw(522)};
  padding-right: 8.4375vw;
  position: relative;
  z-index: 1;

  @media (max-width: 766px) {
    width: auto;
    padding: 0 2rem;
    padding-bottom: 4rem;
  }
`

const Button = styled.button`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
`

const List = styled.ul`
  position: relative;
  margin-top: ${vw(48)};
  padding-bottom: ${vw(120)};

  li {
    padding-bottom: 5vw;
    position: relative;

    &:last-child {
      padding-bottom: 0;

      .line {
        display: none;
      }
    }

    span {
      position: relative;
      z-index: 2;
    }

    &.is-active {
      .title {
        transform: none;

        span {
          color: var(--c-mint-100);
          font-weight: 500;
        }

        p {
          opacity: 1;
        }
      }
    }
  }

  .title {
    color: var(--c-white);
    transform: translate3d(1.5rem, 0, 0);
    text-align: left;
    transition: transform 0.4s, opacity 0.4s;

    span {
      transition: color 0.4s;
    }

    p {
      font-size: 0.85rem;
      padding-top: 0.5vw;
      transition: opacity 0.4s;
      opacity: 0.25;
      line-height: 1.5;
    }
  }

  .line {
    position: absolute;
    height: 100%;
    left: 1.125rem;
    background: var(--c-white);
    width: 1px;
    top: 0;
    z-index: -1;
  }

  @media (max-width: 766px) {
    margin-top: 0;
    padding-top: 2rem;
    padding-bottom: 5rem;
    li {
      padding-bottom: 3rem;
    }
    .title {
      p {
        padding-top: 1rem;
        line-height: 1.8;
      }
    }
  }
`

const Bullet = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  margin-right: 1rem;
  color: var(--c-white);
  font-size: 0.625rem;
  flex-shrink: 0;

  position: relative;
  z-index: 2;
  overflow: hidden;

  &:after,
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    transition: opacity 0.5s, transform 0.5s;
    border: solid 1px var(--c-white);
  }

  &:before {
    background-color: var(--c-blue-101);
    z-index: 0;
  }

  &:after {
    background-color: var(--c-mint-100);
    opacity: 0;
    transform: scale3d(0, 0, 0);
    z-index: 1;
    border-color: var(--c-mint-100);
  }

  ${props =>
    props.active &&
    css`
      color: var(--c-blue-200);
      border-color: var(--c-mint-100);

      &:after {
        transform: none;
        opacity: 1;
      }
    `}
`

const Ele = ({ setIndex }) => {
  const [slide, setSlide] = React.useState(0)
  const { ref, inView } = useInView()

  const handleClick = index => {
    setSlide(index)
    setIndex(index)
  }

  React.useEffect(() => {
    let timer = 0

    timer = setInterval(() => {
      if (inView) {
        if (slide >= 2) {
          setSlide(0)
          setIndex(0)
        } else {
          setSlide(slide + 1)
          setIndex(slide + 1)
        }
      }
    }, 5000)

    return () => {
      clearInterval(timer)
    }
  })

  return (
    <Box ref={ref}>
      <Overline>How XENO Works</Overline>
      <Title>
        <h2>
          Turn your dreams into achievable <span>goals</span>
        </h2>
      </Title>
      <List>
        {slides &&
          slides.map((ele, index) => {
            return (
              <li key={index} className={slide === index ? "is-active" : ""}>
                <Button
                  onClick={() => {
                    handleClick(index)
                  }}
                >
                  <Bullet active={slide === index ? true : false}>
                    <span>{index + 1}</span>
                  </Bullet>
                  <div className="title">
                    <span>{ele.title}</span>
                    <p>{ele.caption}</p>
                  </div>
                  <div className="line"></div>
                </Button>
              </li>
            )
          })}
      </List>
      <GetStartedBtn title="Get Started" id="get_started_button"/>
    </Box>
  )
}
export default Ele
