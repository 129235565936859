export const questions = [
  {
    title: "Where is my money invested?",
    caption: "Anytime when a need arises or when you hit your targets...",
    content: {
      title: "Where is my money invested?",
      objective:
        "All client funds are invested across four asset classes in combinations that are consistent with your unique investment plan. Money Markets (financial instruments with maturities of 1 year or less e.g., Treasury bills, fixed deposits, call deposits, etc.),Bonds (financial instruments with maturities of 2 years of more issued by governments and corporations in East Africa e.g. Treasury bonds, corporate bonds, infrastructure bonds),Domestic equities (shares of Ugandan companies listed on the stock exchanges in Uganda), and Regional equities (shares of other East African companies listed on the stock exchanges across the East African region).",
    },
  },

  {
    title: "What is the minimum amount you can start with?",
    caption: "Anytime when a need arises or when you hit your targets...",
    content: {
      title: "What is the minimum amount you can start with?",
      objective:
        "Investors can start with as low as UGX 10,000. However, we realize everyone’s circumstances are different. One can start with a modest minimum amount and periodically add to their investment account (e.g. monthly contributions) over the investment horizon. Alternatively, you could decide to define a goal (e.g. retirement) and invest a lump sum amount at the beginning.",
    },
  },
  {
    title: "How does XENO Work?",
    caption: "Anytime when a need arises or when you hit your targets...",
    content: {
      title: "How does XENO work?",
      objective:
        "XENO is a goal-based investment service that helps individuals, families, groups, and institutions to plan, save, and invest in their financial goals. We provide both investment advisory and investment management in very simple steps:",
    },
  },

  {
    title: "How do you determine the investor risk profile?",
    caption: "Anytime when a need arises or when you hit your targets...",
    content: {
      title: "How do you determine the investor risk profile?",
      objective:
        "An individual investor’s profile is determined by the following: Financial goal and Time Horizon An investor’s financial goals and time horizon have a bearing on their risk tolerance level. Generally, critical goals and/or short time horizons (e.g. less than five years) may dictate a more conservative approach. On the other hand, a less critical goal and/or longer time horizons may accommodate a more aggressive approach since they have sufficient time to recover from short term drops in the asset values.",
    },
  },

  {
    title: "Why assess my risk profile?",
    caption: "Anytime when a need arises or when you hit your targets...",
    content: {
      title: "Why assess my risk profile",
      objective:
        "An individual investor’s profile is determined by the following: Financial goal and Time Horizon An investor’s financial goals and time horizon have a bearing on their risk tolerance level. Generally, critical goals and/or short time horizons (e.g. less than five years) may dictate a more conservative approach. On the other hand, a less critical goal and/or longer time horizons may accommodate a more aggressive approach since they have sufficient time to recover from short term drops in the asset values.",
    },
  },

  {
    title: "Can I have more than one investment goal?",
    caption: "Anytime when a need arises or when you hit your targets...",
    content: {
      title: "Can I have more than one investment goal?",
      objective:
        "Yes! Of course. Once you visit the platform, you will at first identify one goal (e.g. Build Wealth) to set up your investment account. You can then log into your investment account and add another investment goal (e.g. Fund Emergencies or Fund Education). However, each goal has to have a minimum of UGX 10,000.",
    },
  },
]
