import * as React from "react"
import styled from "styled-components"
import Overline from "../../shared/overline"
import Title from "../../shared/title"
import Text from "../../shared/text"
import GetStartedBtn from "../../shared/get-started-btn"
import { vw } from "../../../utilities/pxtovw"

const Box = styled.div`
  height: 100%;
  flex: 1;

  > section {
    padding-top: ${vw(120)};
    padding-left: 5rem;
    width: ${vw(433)};
  }

  @media (max-width: 766px) {
    height: auto;
    padding: 7.5rem 2rem 4rem;
    > section {
      width: auto;
      padding-left: 0;
      padding-top: 0;
    }
  }
`
const Actions = styled.div`
  padding-top: ${vw(60)};

  @media (max-width: 766px) {
    padding-top: 2.5rem;
  }
`
const Ele = () => {
  return (
    <Box>
      <section>
        <Overline invert>Join XENO</Overline>
        <Title invert>
          <h1>
            Invest your savings. Build your <span>future.</span>
          </h1>
        </Title>
        <Text>
          <p>
            Join over <strong>100,000</strong> people who trust XENO to plan,
            save and invest for their financial goals.
          </p>
        </Text>
        <Actions>
          <GetStartedBtn
            title="Start Investing"
            section="splash"
            id="start_investing_button"
          />
        </Actions>
      </section>
    </Box>
  )
}
export default Ele
